import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {I18N} from "aurelia-i18n";
import {FlashService} from "../../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";
import {BindingSignaler} from "aurelia-templating-resources";
import {DialogService} from "aurelia-dialog";
import {StandardActions} from "../../action/standard-actions";
import {WorkflowService} from "../../workflow/workflow-service";
import {ConfigurationLoader} from "../../table/loader/configuration-loader";
import {RendererService} from "../../table/service/renderer-service";

import "./bus-routing-manager.less";

@customElement('sio-tourism-bus-routing-manager')
@inject(Client, I18N, FlashService, EventAggregator, BindingSignaler, DialogService, StandardActions, WorkflowService, ConfigurationLoader, RendererService)
export class BusRoutingManager {
    @bindable reference;
    @bindable options


    viewReady = false;
    sortingInitialized = false;

    subscribers = [];

    config = {
        routeColumnsConfig: {},
        routeItemColumnsConfig: {},
        locationColumnsConfig: {},
        referenceConfig: {}
    };

    routes = [];
    locations = [];


    constructor(client, i18n, flash, ea, signaler, dialogService, standardActions, workflowService, configurationLoader, renderService) {
        this.client = client;
        this.i18n = i18n;
        this.flash = flash;
        this.ea = ea;
        this.signaler = signaler;
        this.dialogService = dialogService;
        this.actions = standardActions;
        this.workflowService = workflowService;
        this.configLoader = configurationLoader;
        this.renderService = renderService;
    }

    attached() {
        // Add event listeners
        this.eventListeners();
    }


    detached() {
        this.subscribers.forEach(subscriber => {
            subscriber.dispose();
        })
        this.viewReady = false;
    }

    bind() {
        let promises = [];

        promises.push(this.updateReferenceConfig());
        // promises.push(this.updateRouteConfig());
        // promises.push(this.updateRouteItemConfig());
        promises.push(this.updateLocationConfig());

        Promise.all(promises).then(() => {

            let subscriber = this.ea.subscribe('sio_form_post_submit', response => {
                this.ea.publish('sio_routing.reload.items', {config: {modelId: this.reference.modelId}});
            });

            this.subscribers.push(subscriber)

            // populate items at first start
            this.ea.publish('sio_routing.reload.items', {config: {modelId: this.reference.modelId}});
            this.viewReady = true;
        })

    }

    /**
     * some event listeners
     */
    eventListeners() {

        let subscribe;
        // trigger when reload items needed
        subscribe = this.ea.subscribe('sio_routing.reload.items', response => {
            if (response.config.modelId !== this.reference.modelId) {
                return;
            }
            Promise.resolve(this.updates()).then(() => {
                this.signaler.signal('locationsUpdated');
                this.signaler.signal('routesUpdated');
                this.signaler.signal('routingStateUpdate');
                this.ea.publish('sio_routing.reload.items.success', {config: {modelId: this.reference.modelId}});
            })
        });
        this.subscribers.push(subscribe);

    }

    async updateReferenceConfig() {
        const data = await this.configLoader.get(this.reference.modelId, null);
        this.config.referenceConfig = await data;

    }

    async updateRouteConfig() {
        const data = await this.configLoader.get('tourism-bus-routing/route', null);
        this.config.routeColumnsConfig = await data.columns;
    }

    // async updateRouteItemConfig() {
    //     const data = await this.configLoader.get('tourism-bus-routing/route-item', null);
    //     this.config.routeItemColumnsConfig = await data.columns;
    // }

    async updateLocationConfig() {
        const data = await this.configLoader.get('tourism-bus-routing/route-location', null);
        this.config.locationColumnsConfig = await data.columns;
    }

    _getColumnConfigForReferenceField(field) {
        return this.config.referenceConfig.columns.find(config => config.property === field);
    }

    _generalActions() {

        let actions = [];

        let state = this.reference?.state ?? null;

        actions.push(
            // {
            //     state: ['draft', 'active'],
            //     bulk: true,
            //     bulkOnly: false,
            //     buttonClass: "btn btn-default btn-sm",
            //     formId: "tourism-bus/bus-routing-routes-create-planing",
            //     icon: "road",
            //     label: "O",
            //     workflowId: "tourism-bus-routing/bus-routing-routes-create-planing",
            // },
            // {
            //     state: ['draft', 'active'],
            //     bulk: true,
            //     bulkOnly: false,
            //     label: "TN",
            //     icon: "road",
            //     buttonClass: 'au-target btn btn-default btn-sm',
            //     workflowId: 'tourism-bus-routing/bus-routing-routes-create',
            // },
            // {
            //     state: ['draft', 'active'],
            //     bulk: true,
            //     bulkOnly: false,
            //     label: "GE",
            //     icon: "road",
            //     buttonClass: 'au-target btn btn-default btn-sm',
            //     workflowId: 'tourism-bus-routing/direction-generate-route-itinerary',
            // },
            {
                state: ['draft', 'active'],
                bulk: true,
                bulkOnly: false,
                icon: "fa fa-plus",
                buttonClass: 'au-target btn btn-success btn-sm',
                workflowId: 'tourism-bus-routing/route-add',
                active: !this.reference.templateReference || this.reference.overwriteTemplate
            }
        );


        return {
            actions: actions.filter((action) => {
                return action.state.includes(state) && action.active;
            }).map((action) => {
                action.type = 'workflow';
                delete action.state;
                return action;
            }),
            id: this.reference.id,
            modelId: this.reference.modelId
        };
    }

    _routeStateActions() {
        let actions = [];

        let state = this.reference?.state ?? null;

        actions.push(
            {
                state: ['draft', 'disposition'],
                label: 'tourism-bus-routing.route-itinerary.state.activate',
                buttonClass: 'btn btn-success btm-sm',
                confirm: 'tourism-bus-routing.route-itinerary.state.activate-confirm',
                workflowId: "tourism-bus-routing/route-itinerary-state-active",
            },
            {
                state: ['active'],
                label: 'tourism-bus-routing.route-itinerary.state.draft',
                buttonClass: 'btn btn-warning',
                confirm: 'tourism-bus-routing.route-itinerary.state.draft-confirm',
                workflowId: 'tourism-bus-routing/route-itinerary-state-draft',
            },
            {
                state: ['active'],
                label: 'tourism-bus-routing.route-itinerary.state.disposition',
                buttonClass: 'btn btn-info',
                confirm: 'tourism-bus-routing.route-itinerary.state.disposition-confirm',
                workflowId: 'tourism-bus-routing/route-itinerary-state-disposition',
            },
            {
                state: ['active', 'disposition'],
                label: 'tourism-bus-routing.route-itinerary.state.close',
                icon: 'fa fa-lock',
                confirm: 'tourism-bus-routing.route-itinerary.state.close-confirm',
                workflowId: 'tourism-bus-routing/route-itinerary-state-closed',
            },
            {
                state: ['closed'],
                label: 'tourism-bus-routing.route-itinerary.state.open',
                buttonClass: 'btn btn-danger',
                icon: 'fa fa-lock',
                confirm: 'tourism-bus-routing.route-itinerary.state.open-confirm',
                workflowId: 'tourism-bus-routing/route-itinerary-state-open',
            },
        );

        return {
            actions: actions.filter((action) => {
                return action.state.includes(state);
            }).map((action) => {
                action.type = 'workflow';
                // action.buttonClass = 'btn btn-default';
                delete action.state;
                return action;
            }),
            id: this.reference.id,
            modelId: this.reference.modelId
        };
    }

    async updates() {
        this.loading = true;
        await this.updateLocations();
        await this.updateRoutes();
        this.loading = false;
    }

    async updateLocations() {
        let locationConditions = {
            includeArchived: false,
            reference: {
                $eq: {
                    id: this.reference.id,
                    modelId: this.reference.modelId
                }
            }
        };

        let response = await this.client.get('tourism-bus-routing/route-location?conditions=' + JSON.stringify(locationConditions));
        this.locations = await response.items.map(location => {
            if (!(location.participantCount > 0)) {
                location.participantCount = 0;
            }
            return location;
        });

    }

    async updateRoutes() {
        let routeConditions = {
            includeArchived: false,
            reference: {
                $eq: {
                    id: this.reference.id,
                    modelId: this.reference.modelId
                }
            }
        };

        let response = await this.client.get('tourism-bus-routing/route?conditions=' + JSON.stringify(routeConditions));
        let routes = await response.items;

        // await routes.forEach(async (route) => {
        //     let items = await this.fetchItems(route);
        //     route.items = items;
        // });


        this.routes = routes


        console.log('ROUTES', this.routes);

    }

    async fetchItems(route) {

        let conditions = {
            includeArchived: false,
            route: {
                $eq: {
                    id: route.id,
                    modelId: route.modelId
                }
            },
            routeItinerary: {
                $eq: {
                    id: route.reference.id,
                    modelId: route.reference.modelId
                }
            }
        };

        let response = await this.client.get('tourism-bus-routing/route-item?conditions=' + JSON.stringify(conditions));
        return await response.items;
    }

    /**
     * update items by ids
     * @param newItems
     * @param oldItems
     * @param deleteItems
     * @returns {*}
     */
    async updateItemsById(newItems, oldItems, deleteItems = false) {
        newItems.forEach(item => {
            let index = oldItems.findIndex(element => element.id === item.id)
            if (index === -1) {
                oldItems.push(item);
            } else {
                oldItems.splice(index, 1, item);
            }
        })

        if (deleteItems) {
            oldItems = oldItems.filter(item => {
                return newItems.findIndex(element => element.id === item.id) !== -1
            })
        }
        return oldItems;
    }
}
